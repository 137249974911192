<script>
  import api from "@/api"
  import sessionUtils from "@/store/utils"
  import EventBus from "@/eventbus"
  
  export default {
    name: "mail-activation",
    data: function() {
      return {
      }
    },
    computed: {
      canResumeQuestionnaire() {
        return sessionUtils.existsCookie("questStructures") && sessionUtils.existsCookie("questStructuresConsents")
      }
    },
    created() {
      this.$store.dispatch("resetQuestionnaireState")
      if (!("token" in this.$route.query)) {
        this.router.replace({name: "home"})
        EventBus.$emit("errorMessage", "Impossibile attiare l'indirizzo e-mail")
      } else {
        this.$store.dispatch("pushPage", this.$route.name)
        this.$store.dispatch("disableBackButton")
      }
    },
    mounted() {
      api.sendMailActivationToken(this.$route.query.token)
      .then(response => {
        if (response.status == 200) {
          this.resumeActivity();
          EventBus.$emit("successMessage", "Indirizzo e-mail verificato con successo")
        } else {
          EventBus.$emit("errorMessage", "Impossibile attiare l'indirizzo e-mail")
          this.$router.replace({name: "home"})
        }
      }).catch(() => {
        EventBus.$emit("errorMessage", "Impossibile attiare l'indirizzo e-mail")
        this.$router.replace({name: "home"})
      })     
    },
    methods: {
      resumeActivity() {
        if (this.canResumeQuestionnaire) {
          this.$store.dispatch("loadSessionAndDeleteLS")
          // this.$store.dispatch("setQuestionnaireState", "mails")

          this.$store.dispatch("setQuestionnaireState", "load_questions")
          this.$router.replace({name: "questionnaire"})
        } else {
          this.$router.replace({name: "mail"})
        }
      },
    }
  }

</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-4">
          <div class="box is-flex is-flex-direction-column is-align-items-center section">
            <div class="block has-text-centered">
              <h1 class="title is-3">
                {{ $t("title") }}
              </h1>
              <h2 class="subtitle is-5">
                {{ $t("subtitle") }}
              </h2>
            </div>
            <div class="loader is-loading my-loader"></div>
          </div>
        </div>
        
            
      </div>
    </div>  
  </div> 
</template>

<i18n>
{
  "it": {
    "title": "Attivazione in corso",
    "subtitle": "Stiamo sincronizzando questo dispositivo alla tua e-mail",
    "successMessage": "E-mail registrata con successo",
    "errorMessage": "Qualcosa è andato storto nella procedura. Ti consigliamo di riprovare tra qualche minuto",
    "questionnaireButton": "Ritorna al questionario",
    "mailsButton": "Gestisci le e-mail",
    "questionnaireInfoButton": "Torna al questionario",
    "homeButton": "Torna alla home"
  }
}
</i18n>

<style>

</style>